import React, { useState } from 'react'
import styled from 'styled-components'
import Link from './common/link'
import NavMenuButton from './navigation/icon'
import Navigation from './navigation'
import Logo from './logo'

const Container = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 5vh;
  background: ${({ theme }) => theme.primary};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 0;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;

  > :nth-child(2) {
    justify-self: center;
  }
  > :nth-child(3) {
    justify-self: end;
  }
`
const ContainerLink = styled(Link)`
  width: 100%;
  max-width: min(350px, 60%);
  height: 100%;
  z-index: 10;
`

const Header = ({ menuLinks = [], themeToggleLink }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Container>
      <StyledHeader>
        <NavMenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <ContainerLink to='/'>
          <Logo />
        </ContainerLink>
        {themeToggleLink}
        <Navigation menuLinks={menuLinks} isMenuOpen={isMenuOpen} />
      </StyledHeader>
    </Container>
  )
}

export default Header
