import React from 'react'
import styled from 'styled-components'
import Link from './common/link'
import SocialLink from './social-links'
import { P } from './common/text'

const StyledFooter = styled.footer`
  width: 100%;
  background: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.contrast};
  padding: 2rem 1rem;

  a {
    color: ${({ theme }) => theme.brand};
    opacity: 0.7;
    transition: opacity 400ms;
  }
  @media (min-width: 768px) {
    padding: 2rem 4rem 1rem 4rem;
  }
  @media (hover: hover) {
    a:hover {
      opacity: 1;
    }
  }
`
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  margin-bottom: 1rem;

  li {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.light};
  }
  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  > * {
    margin-bottom: 0.5rem;
  }
`

const Text = styled(P)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.light};
`
const Footer = ({ menuLinks = [] }) => {
  return (
    <StyledFooter>
      <List isRow>
        <li>
          <SocialLink account='Youtube' />
        </li>
        <li>
          <SocialLink account='Facebook' />
        </li>
        <li>
          <SocialLink account='Instagram' />
        </li>
        <li>
          <SocialLink account='Twitter' />
        </li>
      </List>
      <List>
        {menuLinks.map((link) => (
          <li key={link.name}>
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </List>
      <List>
        <li>
          Artwork created by{' '}
          <a href='https://www.instagram.com/roelonic/'>Nick Roelofs</a>
        </li>
        <li>
          Website developed by{' '}
          <a href='https://github.com/jnicewander'>Justin Nicewander</a>
        </li>
      </List>
      <Text>
        © {new Date().getFullYear()} The Average Handlebar, All Rights Reserved
      </Text>
    </StyledFooter>
  )
}

export default Footer
