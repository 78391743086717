import '../fonts/typography.css'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    font-size: 16px;
  }
  body {
    letter-spacing: 0;
    font-family: 'Montserrat', sans-serif;
    color: ${({theme}) => theme.contrast};
    background: ${({theme}) => theme.primary};
  }
  a {
    text-decoration: none;
  }
  ul, ol {
    list-style: none;
  }
  li {
    display: inline-block;
  }
`