import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

const LogoV2 = ({ className, ...rest }) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(relativePath: {eq: "logo_v2.1@4x.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `)

  return (
    <GatsbyImage
      image={data.file.childImageSharp.gatsbyImageData}
      alt=""
      className={className}
      {...rest}
    />
  )
}

export default LogoV2

export const LogoDuoPurple = () => (
  <StaticImage
    src="../images/logo_v2.1@4x.png"
    alt="Average Handlebar Logo"
    placeholder="blurred"
    layout="constrained"
    transformOptions={{
      duotone: {
        highlight: "#870A30",
        shadow: "#0d0d0d",
      },
    }}
  />
)