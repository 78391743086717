import React from 'react'
import { FaFacebook, FaYoutube, FaInstagram, FaTwitter } from 'react-icons/fa'
import Link from './common/link'
import styled from 'styled-components'

const SocialLinkTag = ({ account, className, children, ...rest }) => {
  const socialData = {
    Youtube: {
      link: 'https://www.youtube.com/c/TheAverageHandlebar?sub_confirmation=1',
      icon: FaYoutube,
    },
    Facebook: {
      link: 'https://www.facebook.com/TheAverageHandlebar/',
      icon: FaFacebook,
    },
    Instagram: {
      link: 'https://www.instagram.com/theaveragehandlebar/',
      icon: FaInstagram,
    },
    Twitter: {
      link: 'https://twitter.com/HandlebarThe',
      icon: FaTwitter,
    },
  }
  const currentAccount = socialData[account]
  const IconComponent = currentAccount.icon

  return (
    <Link to={currentAccount.link} className={className} {...rest}>
      <IconComponent {...rest} />
      {children}
    </Link>
  )
}

const SocialLink = styled(SocialLinkTag)`
  display: flex;
  align-items: center;
  color: #efeee0;

  svg {
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    margin-right: 0.5em;
  }
`
export default SocialLink
