import React from 'react'
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, className, activeClassName, partiallyActive, ...rest }) => {
  // regex test if "to prop" begins with single slash 
  const internal = /^\/(?!\/)/.test(to)
  
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}        
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} className={className} {...rest}>
      {children}
    </a>
  )
}
export default Link