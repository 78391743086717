import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { GlobalStyle } from '../theme/global-style'
import { lightTheme, darkTheme } from '../theme/themes'
import Header from "./header"
import Footer from "./footer"
import ThemeToggle from './theme-toggle'

// Min-height 100vh required for sticky footer
const Container = styled.div`
  width: 100%;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
`
const Main = styled.main`
  flex-grow: 1;
  padding-bottom: 2.5rem;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  const [theme, setTheme] = useState({...lightTheme})

  const handleClick = e => {
    e.preventDefault()
    theme.id === "light" ? setTheme({...darkTheme}) : setTheme({...lightTheme})
  }

  const themeToggleComponent = <ThemeToggle handleClick={e => handleClick(e)} theme={theme} />

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          menuLinks={data.site.siteMetadata?.menuLinks}
          themeToggleLink={themeToggleComponent}
        />
        <Main>{children}</Main>
        <Footer
          menuLinks={data.site.siteMetadata?.menuLinks}
        />
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout