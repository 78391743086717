import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaMoon, FaSun } from 'react-icons/fa'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
`

const Button = styled.button`
  display: block;
  background: transparent;  
  outline: none;
  border: none;
  overflow: hidden;
  transition: all 500ms;
  padding: .75rem;
  z-index: 10;

  svg {
    display: block;
    color: ${({theme}) => theme.contrast};
    height: 1.5em;
    width: 1.5em;
    animation: ${fadeIn} 1s;
  }
`

const ThemeToggle = ({ handleClick, theme }) => {
  return (
    <Button onClick={handleClick}>
      {theme.id === 'dark' ?
        <FaSun />
      : <FaMoon />}
    </Button>
  )
}

export default ThemeToggle
