import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: clamp(2.369rem, 1.8348rem + 2.3742vw, 4.209rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const H2 = styled.h2`
  font-size: clamp(1.777rem, 1.3764rem + 1.7806vw, 3.157rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const H3 = styled.h3`
  font-size: clamp(1.333rem, 1.0322rem + 1.3368vw, 2.369rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const H4 = styled.h4`
  font-size: clamp(1rem, 0.7744rem + 1.0026vw, 1.777rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const H5 = styled.h5`
  font-size: clamp(0.75rem, 0.5807rem + 0.7523vw, 1.333rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const H6 = styled.h6`
  font-size: clamp(0.563rem, 0.4361rem + 0.5639vw, 1rem);
  font-weight: 900;
  color: ${({theme}) => theme.contrast};
`
export const P = styled.p`
  font-size: clamp(0.563rem, 0.4361rem + 0.5639vw, 1rem);
  color: ${({theme}) => theme.contrast};
`