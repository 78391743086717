export const color = {
  cream: '#efeee0',
  lightBlack: '#242424',
  black: '#0d0d0d',
  lightRed: '#dc143c',
  lightGreen: '#86b974',
  purple: '#240b36',
}

export const darkTheme = {
  id: "dark",
  primary: color.lightBlack, // backgrounds
  contrast: color.cream,  // text
  brand: color.lightGreen,
  accent: color.lightRed,
  dark: color.black,
  light: color.cream,
}

export const lightTheme = {
  id: "light",
  primary: color.cream, // backgrounds
  contrast: color.lightBlack,  // text
  brand: color.lightGreen,
  accent: color.lightRed,
  dark: color.lightBlack,
  light: color.cream,
}