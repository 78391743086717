import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: .75rem;
  &:focus {
    outline: none;
  }
`
const Inner = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: space-around;
  height: 26px;
  width: 26px;
  div {
    width: 26px;
    height: 4px;
    transform-origin: 1px;
    transition: all 300ms linear;
    background: ${({ theme }) => theme.contrast };
    :first-child {
      transform: ${({ isMenuOpen }) => isMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};      
    }
    :nth-child(2) {
      opacity: ${({ isMenuOpen }) => isMenuOpen ? '0' : 1};
      transform: ${({ isMenuOpen }) => isMenuOpen ? 'translateX(-20px)' : 'translateX(0)'};
    }
    :last-child {
      transform: ${({ isMenuOpen }) => isMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }    
  }
`


const Icon = ({ isMenuOpen, setIsMenuOpen }) => {

  const handleClick = e => {
    e.preventDefault()
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <StyledButton
      isMenuOpen={isMenuOpen} 
      onClick={e => handleClick(e)}
    >
    <Inner>
      <div />
      <div />
      <div />
    </Inner>
    </StyledButton>
  )
}

export default Icon
