import React from 'react'
import Link from '../common/link'
import styled from 'styled-components'
import SocialLink from '../social-links'
import { GiHops } from 'react-icons/gi'
import { LogoDuoPurple } from '../logo-v2'

const Nav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: ${(props) =>
    props.isMenuOpen ? 'translateX(0%)' : 'translateX(-100%)'};
  transition: transform 500ms;
  background: #dc143c;
  background: -webkit-linear-gradient(180deg, #dc143c 30%, #240b36);
  background: linear-gradient(180deg, #dc143c 30%, #240b36);
  padding-top: 100px;
  opacity: 0.98;
`
const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 65%;
`
const ListItem = styled.li`
  &,
  a {
    margin: 0;
    padding: 0;
    font-size: 1.65rem;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: ${({ theme }) => theme.contrast};
    text-transform: uppercase;
  }

  :last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;

      svg {
        margin: 1em;
        padding: 0;
      }
    }
  }
`
const LogoContainer = styled.div`
  width: 50%;
  margin: 0 auto;
`
const StyledSocial = styled(SocialLink)`
  font-size: ${(props) => props.size};
  font-weight: 500;
  text-transform: uppercase;
  svg {
    display: inline-block;
  }
`
const Navigation = ({ menuLinks, isMenuOpen }) => {
  return (
    <Nav isMenuOpen={isMenuOpen}>
      <NavList isMenuOpen={isMenuOpen}>
        <ListItem>
          <GiHops size='1.65rem' />
        </ListItem>
        {menuLinks.map((link) => (
          <ListItem key={link.name}>
            <Link to={link.link}>{link.name}</Link>
          </ListItem>
        ))}
        <ListItem>
          <StyledSocial account='Youtube' size='1.65rem'>
            Subscribe
          </StyledSocial>
        </ListItem>
        <ListItem>
          <p>Follow</p>
          <div>
            <StyledSocial account='Facebook' size='1.65rem' />
            <StyledSocial account='Instagram' size='1.65rem' />
            <StyledSocial account='Twitter' size='1.65rem' />
          </div>
        </ListItem>
      </NavList>
      <LogoContainer>
        <LogoDuoPurple />
      </LogoContainer>
    </Nav>
  )
}

export default Navigation
